<template>
  <section id="banner" class="page">
    <banner-image-overlay :fullscreen="true"></banner-image-overlay>
    <div class="main-container">
      <h2 class="title">Reset Password</h2>
      <div class="d-flex justify-content-center">
        <article>
          <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <input name="email" class="form-control" placeholder="Email"
              type="email" autocomplete="username" v-model="email">
            </div>
            <div class="alert alert-danger align-left" v-show="error">
              <div v-html="error"></div>
              <div v-show="userNotActiveError">
                <a @click.stop.prevent="resendActivationEmail">Resend activation email?</a>
              </div>
            </div>
            <div class="alert alert-success align-left" v-show="success">{{ success }}</div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <button type="submit" class="button special">Reset</button>
                </div>
              </div>
            </div>
          </form>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';
import UserApi from '../../api/User';

export default {
  name: 'ResetPassword',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      email: '',
      error: '',
      success: '',
      userNotActiveError: false,
    };
  },
  methods: {
    resendActivationEmail() {
      this.error = '';
      this.success = '';
      this.userNotActiveError = false;
      UserApi
        .resendActivationEmail(this.email)
        .then(({ data }) => {
          this.success = data.message;
        })
        .catch((error) => {
          const contactPage = this.$router.resolve({name: 'Contact'});
          this.error = this.parseError(error, 'Unable to resend the email for activating your account. ' +
            `Please try again, if this error persists <a href="${contactPage}" target="_blank">contact us</a>`)
        });
    },
    onSubmit() {
      this.error = '';
      this.success = '';
      this.userNotActiveError = '';
      UserApi
        .resetPassword(this.email)
        .then(({ data }) => {
          this.success = data.success;
        })
        .catch((error) => {
          // User needs to activate account
          if (error.response.status === 470) {
            this.userNotActiveError = true;
          }
          this.error = this.parseError(error, 'Unable to reset password');
        });
    },
  },
};
</script>

<style scoped>
  strong, b {
    color: #B6B6B6;
  }

  article {
    width: 500px;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: #38AAF0;
  }

  .alert a {
    text-decoration: underline;
    cursor: pointer;
  }

  button[type=submit] {
    float: left;
  }

  #banner {
    height: 100%;
    padding-top: 22vh;
  }

  @media screen and (max-width: 736px) {
    #banner {
      padding-left: 0;
      padding-right: 0;
    }

    .main-container {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  @media screen and (max-width: 480px) {
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
        padding: 0 2.2em;
    }
  }
</style>
